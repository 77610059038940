import { useContext, useRef, useState } from "react";
import AssetContext from "../../../../Data/AssetsContext";
import {
  Button,
  TableCell,
  TableRow,
  TextField,
  debounce,
} from "@mui/material";
import { Food } from "../../../../Data/Assets";
import { Delete } from "@mui/icons-material";
import AxiosContext from "../../../../Data/AxiosContext";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { typeOptions } from "./AddNewIngredient";
import Select from "react-select";

export default function EditFoodRow({ ingredient }: { ingredient: Food }) {
  const { axiosHelper } = useContext(AxiosContext);
  const { ingredients, setIngredients } = useContext(AssetContext);
  const [name, setName] = useState(ingredient.Name ?? "");
  const [type, setType] = useState(ingredient.Type ?? "");
  const [lipides, setLipides] = useState(ingredient.Lipides ?? "");
  const [glucides, setGlucides] = useState(ingredient.Glucides ?? "");
  const [proteines, setProteines] = useState(ingredient.Proteines ?? "");
  const debouncedUpdates = useRef<any>({}).current;

  // const deleteFood = () => {
  //   const swalOptions: SweetAlertOptions = {
  //     title: `T'es sur de vouloir supprimer cet aliment ? Si l'aliment est attribué a un élève, ça peut causer des soucis !`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Oui",
  //     cancelButtonText: "Non",
  //     scrollbarPadding: false,
  //   };
  //   Swal.fire(swalOptions).then((result) => {
  //     if (result.isConfirmed) {
  //       axiosHelper
  //         .delete(`api/Assets/Food/${ingredient.Soid}`)
  //         .then((result) => {
  //           let tempIngredients = [...ingredients];
  //           const index = tempIngredients.findIndex(
  //             (i) => i.Soid === ingredient.Soid
  //           );
  //           tempIngredients.splice(index, 1);
  //           setIngredients(tempIngredients);
  //         });
  //     }
  //   });
  // };

  const deleteFood = () => {
    axiosHelper(`/api/Assets/${ingredient.Soid}/SafeDelete`)
      .then((result) => {
        const list = result.data; // Expecting an array of { FullName: string, DaysWithFoodSoid: string[] }
        if (list.length === 0) {
          const swalOptions: SweetAlertOptions = {
            title: `T'es sur de vouloir supprimer cet aliment ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
            scrollbarPadding: false,
          };
          Swal.fire(swalOptions).then((result) => {
            if (result.isConfirmed) {
              axiosHelper
                .delete(`api/Assets/Food/${ingredient.Soid}`)
                .then((result) => {
                  let tempIngredients = [...ingredients];
                  const index = tempIngredients.findIndex(
                    (i) => i.Soid === ingredient.Soid
                  );
                  tempIngredients.splice(index, 1);
                  setIngredients(tempIngredients);
                });
            }
          });
          return;
        }
        const generateListHTML = (
          members: { FullName: string; DaysWithFoodSoid: string[] }[]
        ) => {
          if (!members || members.length === 0)
            return "<p>No members found.</p>";

          let listHTML = "<ul>";
          members.forEach((member) => {
            const daysText =
              member.DaysWithFoodSoid.length > 0
                ? ` (${member.DaysWithFoodSoid.join(", ")})`
                : ""; // Add days only if they exist
            listHTML += `<li>${member.FullName}${daysText}</li>`;
          });
          listHTML += "</ul>";
          return listHTML;
        };

        Swal.fire({
          title: "Attention",
          html: generateListHTML(list),
          icon: "warning",
          confirmButtonText: "Close",
          width: "600px",
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        Swal.fire({
          title: "Error",
          text: "An error occurred while fetching the data.",
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  };

  const getDebouncedUpdate = (fieldName: string) => {
    if (!debouncedUpdates[fieldName]) {
      debouncedUpdates[fieldName] = debounce((val: any) => {
        const data: any = {};
        data.FieldName = fieldName;
        data.Data = val;
        axiosHelper
          .post(`/api/Assets/Food/${ingredient.Soid}`, data)
          .then((result) => {
            let tempIngredients = [...ingredients] as Food[];
            const food = tempIngredients.find(
              (i) => i.Soid === ingredient.Soid
            );
            if (!food) return;
            switch (fieldName) {
              case "Name":
                ingredient.Name = result.data.Data;
                break;
              case "Type":
                ingredient.Type = result.data.Data;

                break;
              case "Calories":
                ingredient.Calories = result.data.Data;

                break;
              case "Glucides":
                ingredient.Glucides = result.data.Data;

                break;
              case "Lipides":
                ingredient.Lipides = result.data.Data;

                break;
              case "Proteines":
                ingredient.Proteines = result.data.Data;

                break;
            }
            setIngredients(tempIngredients);
          });
      }, 800);
    }
    return debouncedUpdates[fieldName];
  };

  const updateField = (value: string, fieldName: string) => {
    switch (fieldName) {
      case "Name":
        setName(value);
        break;
      case "Type":
        setType(value);
        break;
      case "Lipides":
        setLipides(parseFloat(value));
        break;
      case "Glucides":
        setGlucides(parseFloat(value));
        break;
      case "Proteines":
        setProteines(parseFloat(value));
        break;
      default:
        break;
    }
    const debouncedUpdate = getDebouncedUpdate(fieldName);
    debouncedUpdate(value);
  };

  return (
    <TableRow>
      <TableCell>
        <TextField
          onChange={(e) => updateField(e.target.value, "Name")}
          value={name}
          fullWidth
          size="small"
        />
      </TableCell>
      <TableCell>
        <Select
          value={{ label: type, value: type }}
          options={typeOptions}
          onChange={(e) => updateField(e?.value ?? "", "Type")}
        />
      </TableCell>
      <TableCell>
        <TextField
          onChange={(e) => updateField(e.target.value, "Glucides")}
          value={glucides}
          fullWidth
          type="number"
          inputProps={{
            step: "0.01",
          }}
          size="small"
        />
      </TableCell>
      <TableCell>
        <TextField
          onChange={(e) => updateField(e.target.value, "Lipides")}
          value={lipides}
          fullWidth
          type="number"
          inputProps={{
            step: "0.01",
          }}
          size="small"
        />
      </TableCell>
      <TableCell>
        <TextField
          onChange={(e) => updateField(e.target.value, "Proteines")}
          value={proteines}
          fullWidth
          type="number"
          inputProps={{
            step: "0.01",
          }}
          size="small"
        />
      </TableCell>
      <TableCell>
        <Button onClick={deleteFood}>
          <Delete />
        </Button>
      </TableCell>
    </TableRow>
  );
}
