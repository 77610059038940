import { useContext, useState } from "react";
import AssetContext from "../../../../Data/AssetsContext";
import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddNewIngredient from "./AddNewIngredient";
import EditFoodRow from "./EditFoodRow";
import { Delete } from "@mui/icons-material";
import AxiosContext from "../../../../Data/AxiosContext";
import Swal, { SweetAlertOptions } from "sweetalert2";

export default function IngredientList() {
  const { axiosHelper } = useContext(AxiosContext);
  const { ingredients, setIngredients } = useContext(AssetContext);
  const [editMode, setEditMode] = useState(false);
  const [isAddNewVisible, setIsAddNewVisible] = useState(false);

  const deleteFood = (foodSoid: string) => {
    axiosHelper(`/api/Assets/${foodSoid}/SafeDelete`)
      .then((result) => {
        const list = result.data; // Expecting an array of { FullName: string, DaysWithFoodSoid: string[] }
        if (list.length === 0) {
          const swalOptions: SweetAlertOptions = {
            title: `T'es sur de vouloir supprimer cet aliment ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
            scrollbarPadding: false,
          };
          Swal.fire(swalOptions).then((result) => {
            if (result.isConfirmed) {
              axiosHelper
                .delete(`api/Assets/Food/${foodSoid}`)
                .then((result) => {
                  let tempIngredients = [...ingredients];
                  const index = tempIngredients.findIndex(
                    (i) => i.Soid === foodSoid
                  );
                  tempIngredients.splice(index, 1);
                  setIngredients(tempIngredients);
                });
            }
          });
          return;
        }
        const generateListHTML = (
          members: { FullName: string; DaysWithFoodSoid: string[] }[]
        ) => {
          if (!members || members.length === 0)
            return "<p>No members found.</p>";

          let listHTML = "<ul>";
          members.forEach((member) => {
            const daysText =
              member.DaysWithFoodSoid.length > 0
                ? ` (${member.DaysWithFoodSoid.join(", ")})`
                : ""; // Add days only if they exist
            listHTML += `<li>${member.FullName}${daysText}</li>`;
          });
          listHTML += "</ul>";
          return listHTML;
        };

        Swal.fire({
          title: "Attention",
          html: generateListHTML(list),
          icon: "warning",
          confirmButtonText: "Close",
          width: "600px",
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        Swal.fire({
          title: "Error",
          text: "An error occurred while fetching the data.",
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Box m={"10px"}>
          <Button
            variant="contained"
            sx={{ marginRight: "10px" }}
            onClick={() => setIsAddNewVisible(true)}
          >
            Ajouter
          </Button>
          <FormControlLabel
            control={
              <Switch
                checked={editMode}
                onChange={() => setEditMode(!editMode)}
              />
            }
            label="Mode Modif."
          />
        </Box>
        <TableContainer>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Glucides</TableCell>
                <TableCell>Lipides</TableCell>
                <TableCell>Protéines</TableCell>
                <TableCell>Supprimer</TableCell>
              </TableRow>
            </TableHead>
            {!editMode && (
              <TableBody>
                {ingredients &&
                  ingredients.map((ingredient) => (
                    <TableRow key={ingredient.Soid}>
                      <TableCell>{ingredient.Name}</TableCell>
                      <TableCell>{ingredient.Type}</TableCell>
                      <TableCell>{ingredient.Glucides}</TableCell>
                      <TableCell>{ingredient.Lipides}</TableCell>
                      <TableCell>{ingredient.Proteines}</TableCell>
                      <TableCell>
                        <Button onClick={() => deleteFood(ingredient.Soid)}>
                          <Delete />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {editMode && (
              <TableBody>
                {ingredients &&
                  ingredients.map((ingredient) => (
                    <EditFoodRow
                      key={`E-${ingredient.Soid}`}
                      ingredient={ingredient}
                    />
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <AddNewIngredient open={isAddNewVisible} setIsOpen={setIsAddNewVisible} />
    </>
  );
}
