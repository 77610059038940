import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SingleValue } from "react-select";
import { useContext, useState } from "react";
import AxiosContext from "../../../../Data/AxiosContext";
import AssetContext from "../../../../Data/AssetsContext";

export const typeOptions = [
  { label: "Fruits", value: "Fruits" },
  { label: "Légumes", value: "Légumes" },
  { label: "Céréales et dérivés", value: "Céréales et dérivés" },
  { label: "Légumineuses", value: "Légumineuses" },
  {
    label: "Protéines animales et végétales",
    value: "Protéines animales et végétales",
  },
  {
    label: "Produits laitiers et alternatives",
    value: "Produits laitiers et alternatives",
  },
  { label: "Matières grasses", value: "Matières grasses" },
  { label: "Produits sucrés", value: "Produits sucrés" },
  { label: "Boissons", value: "Boissons" },
  { label: "Condiments et épices", value: "Condiments et épices" },
  {
    label: "Plats préparés et transformés",
    value: "Plats préparés et transformés",
  },
  {
    label: "Snacks et aliments industriels",
    value: "Snacks et aliments industriels",
  },
];


function areAllFieldsFilled(
  name: string,
  type: string,
  glucides: string,
  lipides: string,
  proteines: string
) {
  return (
    name !== "" && type && glucides !== "" && lipides !== "" && proteines !== ""
  );
}

export default function AddNewIngredient({
  open,
  setIsOpen,
}: {
  open: boolean;
  setIsOpen: any;
}) {
  const { axiosHelper } = useContext(AxiosContext);
  const { setIngredients } = useContext(AssetContext);
  const [name, setName] = useState("");
  const [type, setType] =
    useState<SingleValue<{ label: string; value: string }>>();
  const [glucides, setGlucides] = useState("0");
  const [proteines, setProteines] = useState("0");
  const [lipides, setLipides] = useState("0");

  const isSaveButtonDisabled = !areAllFieldsFilled(
    name,
    type?.value ?? "",
    glucides,
    lipides,
    proteines
  );

  const saveNewIngredient = () => {
    const data: any = {
      Name: name,
      Type: type?.value,
      Glucides: parseFloat(glucides) / 100,
      Lipides: parseFloat(lipides) / 100,
      Proteines: parseFloat(proteines) / 100,
    };
    axiosHelper.post(`/api/Assets/Food`, data).then(() => {
      axiosHelper(`/api/Assets/Ingredients`).then((result) => {
        setIngredients(result.data);
        setIsOpen(false);
      });
    });
  };

  return (
    <Dialog open={open} onClose={() => setIsOpen(false)}>
      <DialogTitle>Ajouter Nouvel Ingredient</DialogTitle>
      <DialogContent>
        <Box>
          <Grid container>
            <Grid item xs={6}>
              <Box p={1}>
                <Typography>Nom</Typography>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  multiline={true}
                  size="small"
                />
              </Box>
              <Box p={1}>
                <Typography>Type</Typography>
                <Select options={typeOptions} onChange={(e) => setType(e)} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box p={1}>
                <Typography>Glucides (Pour 100g)</Typography>
                <TextField
                  type="number"
                  value={glucides}
                  onChange={(e) => setGlucides(e.target.value)}
                  size="small"
                />
              </Box>
              <Box p={1}>
                <Typography>Lipides (Pour 100g)</Typography>
                <TextField
                  type="number"
                  value={lipides}
                  onChange={(e) => setLipides(e.target.value)}
                  size="small"
                />
              </Box>
              <Box p={1}>
                <Typography>Proteines (Pour 100g)</Typography>
                <TextField
                  type="number"
                  value={proteines}
                  onChange={(e) => setProteines(e.target.value)}
                  size="small"
                />
              </Box>
            </Grid>
          </Grid>
          <Box textAlign={"center"}>
            <Button
              variant="outlined"
              onClick={saveNewIngredient}
              disabled={isSaveButtonDisabled}
            >
              Sauver
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
