import { Box, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import { IDay } from "../../../Data/Member";
import { useContext, useEffect, useState } from "react";
import AssetContext from "../../../Data/AssetsContext";
import TotalSummary from "../../Admin/Member/Repas/TotalSummary";

export default function Day({ day }: { day: IDay }) {
  const { ingredients } = useContext(AssetContext);
  const [globalLipides, setGlobalLipides] = useState<number>(0);
  const [globalGlucides, setGlobalGlucides] = useState<number>(0);
  const [globalProteines, setGlobalProteines] = useState<number>(0);
  
  let totalLipides = 0;
  let totalGlucides = 0;
  let totalProteines = 0;

  day.Meals.forEach((meal) => {
    meal.MealItems.forEach((mealItem) => {
      const ingredient = ingredients.find((i) => i.Soid === mealItem.FoodSoid);
      if (ingredient) {
        totalLipides += ingredient.Lipides * mealItem.Quantity;
        totalGlucides += ingredient.Glucides * mealItem.Quantity;
        totalProteines += ingredient.Proteines * mealItem.Quantity;
      }
    });
  });

  // Update state only once
  useEffect(() => {
    setGlobalLipides(totalLipides);
    setGlobalGlucides(totalGlucides);
    setGlobalProteines(totalProteines);
  }, [totalLipides, totalGlucides, totalProteines]);

  return (
    <Box mt={2}>
      {day.Meals.map((meal, index) => {
        let lipides = 0;
        let glucides = 0;
        let proteines = 0;

        meal.MealItems.forEach((mealItem) => {
          const ingredient = ingredients.find(
            (i) => i.Soid === mealItem.FoodSoid
          );
          if (ingredient) {
            lipides += ingredient.Lipides * mealItem.Quantity;
            glucides += ingredient.Glucides * mealItem.Quantity;
            proteines += ingredient.Proteines * mealItem.Quantity;
          }
        });
        const calories = (glucides * 4 + lipides * 9 + proteines * 4).toFixed(
          2
        );

        return (
          <Card
            key={meal.Soid}
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.125)",
              borderRadius: "0.25rem",
              backgroundColor: "#fff",
              color: "text.primary",
              textAlign: "center",
              marginBottom: "0.5rem",
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6">{`Repas ${index + 1}`}</Typography>
              }
              sx={{
                backgroundColor: "whitesmoke",
                color: "text.primary",
                padding: "0.75rem 1.25rem",
                borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
                height: "1rem",
              }}
            />
            <CardContent sx={{ padding: "1.25rem" }}>
              {meal.MealItems.map((item) => (
                <Box key={item.FoodSoid}>
                  {ingredients.find((i) => i.Soid === item.FoodSoid)?.Name}
                  {": "}
                  {item.Quantity}g
                </Box>
              ))}
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "0.75rem 1.25rem",
                borderTop: "1px solid rgba(0, 0, 0, 0.125)",
                backgroundColor: "whitesmoke",
                minHeight: "0.9rem",
                maxHeight: "1.5rem",
                color: "grey",
              }}
            >
              <Box>Glucides: {glucides.toFixed(2)}g</Box>
              <Box>Lipides: {lipides.toFixed(2)}g</Box>
              <Box>Proteines: {proteines.toFixed(2)}g</Box>
              <Box>Calories: {calories} kcal</Box>
            </CardActions>
          </Card>
        );
      })}
      <TotalSummary
        glucides={globalGlucides}
        lipides={globalLipides}
        proteines={globalProteines}
        global={false}
      />
    </Box>
  );
}
